import * as icons from './icons';

export const ProductFormatIconGroup = {
  tb: icons.isaArtikelTaschenbuch,
  hc: icons.isaArtikelKartoniert,
  au: icons.isaArtikelCd,
  ka: icons.isaArtikelSonstige,
  sw: icons.isaArtikelCd,
  nb: icons.isaArtikelGame,
};

export const IsaIcons = icons;
